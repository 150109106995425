<template>
	<!-- 报告编辑 -->
	<div class="ReportEditing">
		<div class="ReportEditing1">
			<div style="position: relative;margin-right: 10px;">
				<el-button type="primary" icon="el-icon-upload2">上传封面</el-button>
				<input type="file" accept="application/pdf" @change="files" @click="downFilePdf(1)"
					style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;opacity: 0;cursor: pointer;">
			</div>
			<div style="position: relative;margin-right: 10px;">
				<el-button type="primary" icon="el-icon-upload2">上传内容</el-button>
				<input type="file" accept="application/pdf" @change="files" @click="downFilePdf(2)"
					style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;opacity: 0;cursor: pointer;">
			</div>
			<div style="position: relative;margin-right: 10px;">
				<el-button type="primary" icon="el-icon-upload2">上传验证设备证书</el-button>
				<input type="file" accept="application/pdf" @change="files" @click="downFilePdf(3)"
					style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;opacity: 0;cursor: pointer;">
			</div>

			<el-button type="primary" :disabled="docxDis" icon="el-icon-download" @click="downFileToZip()">下载docx
			</el-button>
			<el-button type="primary" :disabled="PreForm.T_pdf1===''?true:false" icon="el-icon-view"
				@click="LookPdf(PreForm.T_pdf1,1)">预览封面
			</el-button>
			<el-button type="primary" :disabled="PreForm.T_pdf2===''?true:false" icon="el-icon-view"
				@click="LookPdf(PreForm.T_pdf2,2)">预览内容
			</el-button>
			<el-button type="primary" :disabled="PreForm.T_pdf3===''?true:false" icon="el-icon-view"
				@click="LookPdf(PreForm.T_pdf3,3)">预览验证设备证书
			</el-button>
		</div>
		<div class="ReportEditing2">
			<el-descriptions border>
				<el-descriptions-item label="报告名称">
					<el-tag size="mini">{{PreForm.T_name}}</el-tag>
				</el-descriptions-item>
				<el-descriptions-item label="创建时间">
					<el-tag size="mini">{{PreForm.CreateTime | formatDate(PreForm.CreateTime)}}</el-tag>
				</el-descriptions-item>
				<el-descriptions-item label="修改时间">
					<el-tag size="mini">{{PreForm.UpdateTime | formatDate(PreForm.UpdateTime)}}</el-tag>
				</el-descriptions-item>
			</el-descriptions>
		</div>
		<div style="margin-bottom: 20px;font-weight: bold;font-size: 18px;" v-if="pdfSrc!=''">
			{{titles}}
			<span>
				共{{numPages}}页
			</span>
		</div>
		<div class="ReportEditing3">
			<div class="ReportEditing3_2" v-if="pdfSrc===''">
				<el-empty :image-size="100" description="暂无可预览PDF数据显示"></el-empty>
			</div>
			<div class="ReportEditing3_1" v-else>
				<pdf v-for="item in numPages" :key="item" :src="pdfSrc" :page="item" ref="pdf"></pdf>
			</div>
		</div>
	</div>
</template>

<script>
	import * as qiniu from 'qiniu-js';
	import pdf from 'vue-pdf'
	import {
		TaskGetDocx
	} from '../../../../api/CorpManage.js'
	import {
		PDFToken,
		PostUp
	} from '../../../../api/pdfToken.js'
	import fileDownload from 'js-file-download'
	import Axios from 'axios'
	export default {
		components: {
			pdf
		},
		data() {
			return {
				titles:'',
				pdfSrc: "", //http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf
				numPages: null, // pdf 总页数
				qiniuToken: null,
				file: null, //点击上传的pdf资源包
				PreForm: {
					T_name: '',
					CreateTime: '',
					UpdateTime: '',
					T_pdf1: "",
					T_pdf2: "",
					T_pdf3: "",
				},
				pdfkey: '',
				downUrl: '',
				docxDis: true,
			}
		},
		filters: {
			formatDate: function(time) { //时间戳转换
				var date = new Date(time)
				let y = date.getFullYear();
				let MM = date.getMonth() + 1;
				MM = MM < 10 ? ('0' + MM) : MM;
				let d = date.getDate();
				d = d < 10 ? ('0' + d) : d;
				let h = date.getHours();
				h = h < 10 ? ('0' + h) : h;
				let m = date.getMinutes();
				m = m < 10 ? ('0' + m) : m;
				let s = date.getSeconds();
				s = s < 10 ? ('0' + s) : s;
				return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
			}
		},
		mounted() {
			this.GetTaskGetDocx()
		},
		methods: {
			LookPdf(e,i) {
				i===1?this.titles='预览封面：':i===2?this.titles='预览内容：':i===3?this.titles='预览验证设备证书：':''
				this.pdfSrc =e
				this.getNumPages()
			},
			getNumPages() {
				if (this.pdfSrc) { //不为空
					let loadingTask = pdf.createLoadingTask(this.pdfSrc)
					loadingTask.promise.then(pdf => {
						this.numPages = pdf.numPages
					}).catch(err => {
						console.error('pdf 加载失败', err);
					})
				} else { //为空
					return
				}
			},
			downFilePdf(e) {
				this.pdfkey = e
			},
			files(e) { //上传图片到七牛云拿url
				var _this = this
				this.file = e.target.files[0];
				var strtype = this.file.name.substring(this.file.name.lastIndexOf('.') + 1); //获取后缀 png jpg
				PDFToken({
					T_suffix: strtype
				}).then(res => {
					if (res.data.Code == 200) {
						_this.qiniuToken = res.data.Data
						_this.QiniuYun()
					} else {
						_this.$message.error(res.data.Msg);
					}
				})
			},
			QiniuYun() {
				var _this = this
				var key = _this.file.name || null;
				var config = {
					useCdnDomain: false, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
					region: qiniu.region.z2,
					domain: "https://qiniu.region.z2", //配置好的七牛云域名  如   https://cdn.qniyun.com/
					chunkSize: 1000, //每个分片的大小，单位mb，默认值3
					forceDirect: false //直传还是断点续传方式，true为直传
				};
				var putExtra = {
					fname: _this.file.name, //文件原始文件名
					params: {},
					mimeType: [] || null
				};
				var observable = qiniu.upload(_this.file, key, _this.qiniuToken, putExtra, config);
				//   设置实例的监听对象
				var observer = {
					next(res) {
						//上传进度
						_this.filePercent = parseInt(res.total.percent)
						if (_this.filePercent === 100) {
							console.log('实例监听对象', res)
						}
						//
					},
					// 接收上传错误信息
					error(err) {
						switch (err.code) {
							case 413:
								_this.$message.error('错了哦，图片可能太大了哦');
								break
							case 408:
								_this.$message.error('错了哦，请求超时');
								break
							default:
								_this.$message.error('错了哦，上传错误');
						}
					},
					// 接收上传完成后的信息
					complete(com) {
						var taskUps = {
							T_task_id: _this.$store.state.T_task_id.T_task_id
						}
						if (_this.pdfkey === 1) {
							_this.$set(taskUps, 'T_pdf1', com.key)
						} else if (_this.pdfkey === 2) {
							_this.$set(taskUps, 'T_pdf2', com.key)
						} else if (_this.pdfkey === 3) {
							_this.$set(taskUps, 'T_pdf3', com.key)
						}
						_this.PostUpApi(taskUps)
					}
				};
				observable.subscribe(observer);
			},
			PostUpApi(e) {
				PostUp(e).then(res => {
					if (res.data.Code === 200 && res.data.Msg === "ok!") {
						this.GetTaskGetDocx()
						this.getNumPages()
						this.$message({
							message: "PDF文件上传成功",
							showClose: true,
							type: "success"
						});
					}
				})
			},
			GetTaskGetDocx() {
				TaskGetDocx({
					T_task_id: this.$store.state.T_task_id.T_task_id
				}).then(res => {
					console.log('报告', res)
					this.downUrl = res.data.Data.T_doc2
					this.PreForm.T_name = res.data.Data.T_name
					this.PreForm.CreateTime = res.data.Data.CreateTime
					this.PreForm.UpdateTime = res.data.Data.UpdateTime
					this.PreForm.T_pdf1 = res.data.Data.T_pdf1
					this.PreForm.T_pdf2 = res.data.Data.T_pdf2
					this.PreForm.T_pdf3 = res.data.Data.T_pdf3
					this.downUrl === '' ? this.docxDis = true : this.docxDis = false
				})
			},
			downFileToZip() {
				Axios({
					method: 'get',
					url: this.downUrl,
					responseType: 'blob'
				}).then(res => {
					console.log(res)
					fileDownload(res.data, this.NewTime() + '.docx');
				});
			},
			NewTime() {
				var Time = new Date()
				let yy = Time.getFullYear();
				let mm = Time.getMonth() + 1 < 10 ? '0' + Number(Time.getMonth() + 1) : Time.getMonth() + 1;
				let dd = Time.getDate() < 10 ? '0' + Time.getDate() : Time.getDate();
				let hh = Time.getHours() < 10 ? '0' + Time.getHours() : Time.getHours();
				let mf = Time.getMinutes() < 10 ? '0' + Time.getMinutes() : Time.getMinutes();
				let ss = Time.getSeconds() < 10 ? '0' + Time.getSeconds() : Time.getSeconds();
				return yy + mm + dd + hh + mf + ss
			}
		}
	}
</script>

<style>
	.ReportEditing {
		background: #fff;
		padding: 20px;
		border-radius: 5px;
	}

	.ReportEditing1 {
		margin-bottom: 20px;
		display: flex;
		align-items: center;
	}

	.ReportEditing2 {
		margin-bottom: 20px;
	}

	.ReportEditing3 {}

	.ReportEditing3_1 {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 20px;
	}

	.ReportEditing3_1>span {
		border: 1px solid #DCDFE6;
	}

	.ReportEditing3_2 {
		padding: 50px 0;
		border: 1px solid #DCDFE6;
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.ReportEditing2>.el-descriptions>.el-descriptions__body>table>tbody>tr>td>span{
		height: 32px;
		padding: 0 10px;
		line-height: 30px;
		font-size: 14px;
		white-space: nowrap;
	}
</style>
