// 图片资源
import {
	axios
} from '../utils/http.js'

const PDFApi = {
	PostToken:'/UpFileToken',//获取七牛云token
	PostUp:'/Task/Up'
}

//获取七牛云token
export function PDFToken(data) {
	return axios({
		url: PDFApi.PostToken,
		method: 'POST',
		data: data
	})
}

//图片列表
export function PostUp(data) {
	return axios({
		url: PDFApi.PostUp,
		method: 'POST',
		data: data
	})
}